// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //api: 'http://localhost:5000',
  // clinic: {
  //   id:    '1a73aeda-359f-4779-b0b5-7cc4655793cb'
  // },
  clinic: {
    id:    '2327c090-ac34-44c6-b025-bf5733d5396b'
  },
  api: 'https://agenda-api-cardiovida.tecnoclinsaude.com.br',
  sso: 'https://sso.tecnoclinsaude.com.br/realms/agenda-mobile-cardiovida/protocol/openid-connect',
  client_secret: 'n8JmuHWRuSEkuR6wwNbkGXh0DCZJ9aao',
  googleMapsApiKey: "AIzaSyBRhOQHnTq2zRKMkqWK3hLGSbw1_LafQ7Q",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
