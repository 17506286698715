export const environment = {
  production: true,
  api: 'https://agenda-api.cardiovidaudi.com.br',
  clinic: {
    id: '2327c090-ac34-44c6-b025-bf5733d5396b',
  },
  sso: 'https://sso.cardiovidaudi.com.br/realms/agenda-mobile-cardiovida/protocol/openid-connect',
  client_secret: 'n8JmuHWRuSEkuR6wwNbkGXh0DCZJ9aao',
  googleMapsApiKey: "AIzaSyBRhOQHnTq2zRKMkqWK3hLGSbw1_LafQ7Q",
};
